import moment from 'moment';
import React, { FC } from 'react';

import './style.css';

type IProps = {
    onDone: Function;
    form: any;
    showDate: boolean;
}

const ReturningResult: FC<IProps> = (props: IProps) => (
    <>
        <p style={{ marginBottom: '2.1875rem' }}>
        Here's when drug checking results will be available for the sample. The sample ID is required to access results. You may wish to record this information on a reminder card for service users.        </p>
        <div className="remindercard">
            {props.form.result &&
                <>
                <div className='newrow'>
                    <div className='col col-12'>
<div className="text">Sample ID:</div>
                    <div className="bold mb">{props.form.sample_id.text}</div>
                    </div>

                    </div>
                {props.showDate && <div className='newrow'>
                        <div className='col col-6'>
                            <div className="text">Date:</div>
                    <div className="bold mb">{moment(props.form.result.return_date).format("dddd, MMMM D, YYYY")}</div>
                        </div>
                        <div  className='col col-6'>
                             <div className="text">No later than:</div>
                    <div className="bold mb">12:00 PM</div>
                        </div>

                    </div>}


                </>
            }
        </div>
        <div className="review banner">
            <div className="bannercontent">
                <button className="btn btn1" onClick={() => props.onDone()}>Done</button>
            </div>
        </div>
    </>
)

export default ReturningResult;
