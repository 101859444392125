import React, {FC} from 'react';
import {displayFormula} from '../../helpers';
import Icons from '../../Icons';

import {DrugFound} from '../../types';
import {getPerms} from '../../requests';
import Preamble from './preamble';

const Noteworthy: FC = () => {
    return <span className="noteworthy"><b>(!)</b></span>;
}

type IProps = {
    isDraft: boolean;
    drugs: any[];
    weight?: number;
    isLiquid: boolean;
    performsCollection: boolean;
}

const DrugsFound: FC<IProps> = (props: IProps) => {
    const drugs: any[] = props.performsCollection ? props.drugs.filter(d => d.custom_drug || d.drug.report_to_public) : props.drugs
    const topDrugs = drugs.filter((drug: DrugFound) => drug.weight && drug.weight > 0 && (drug.drug?.report_to_public || drug.custom_drug));
    const bottomDrugs = props.isLiquid ? drugs : drugs.filter((drug: DrugFound) => !drug.weight && (drug.drug?.report_to_public  || drug.custom_drug));

    topDrugs.sort((a, b) => b.weight - a.weight)
    bottomDrugs.sort((a, b) => b.percent - a.percent)

    let topDrugsWeight = 0;
    let topDrugsPercent = 0;
    topDrugs.forEach((td: any) => {
        topDrugsWeight += +td.weight;
        topDrugsPercent += +td.percent;
    })

    let bottomDrugsWeight = 0;
    bottomDrugs.forEach((bd: any) => {
        bottomDrugsWeight += +bd.weight
    })

    if (props.weight && topDrugsWeight > 0) {
        bottomDrugsWeight = +props.weight - topDrugsWeight;
    }

    const {performs_analysis} = getPerms();

    const getDrugName = (drug: any) => {
        if (drug.custom_drug) return drug.custom_drug
        if (performs_analysis) return drug.drug?.name
        if (drug.drug.report_to_client_name) return drug.drug.report_to_client_name
        return drug.drug?.name
    }

    const isSemiQuantified = (bottomDrugsWeight === 0 && topDrugsWeight === 0) || props.isLiquid
    const isQuantified = (bottomDrugsWeight > 0 || topDrugsWeight > 0) && !props.isLiquid

    let cannotIdentify = 100
    if (props.weight && topDrugs.length > 0) {
        const weight = +props.weight;
        topDrugs.forEach(d => {
            cannotIdentify -= parseFloat(((+d.weight / weight) * 100).toFixed(2))
        })
    }

    const noDrugsFound = drugs.length === 0 && !props.isDraft
    const oneDrugFound = drugs.length === 1 && isSemiQuantified
    const hasIndicator = drugs.length > 1 && bottomDrugs.length > 0

    return (
        <div className="drugsfound">
            {(!performs_analysis &&  !noDrugsFound && !oneDrugFound)&&
                <Preamble
                    isQuantified={isQuantified}
                    isSemiQuantified={isSemiQuantified}
                    noDrugsFound={noDrugsFound}
                    oneDrugFound={oneDrugFound}
                    hasIndicator={hasIndicator}
                />
            }
            {!performs_analysis &&
                <div className="title">
                    <div>
                        {oneDrugFound && <p style={{marginBottom: '1rem'}}>Substance Found:</p>}
                        {!oneDrugFound && <p style={{marginBottom: '1rem'}}>Substances Found:</p>}
                        {drugs.length === 0 && !props.isDraft &&
                            <div className="nodrugs">No substances were found in this sample. The could be because: (i) no substances are present in your drug, (ii) no substances were in the sample you submitted to be checked as a result of the Chocolate Chip Cookie Effect, or (iii) we missed substances due to technological limitations.</div>
                        }
                        {oneDrugFound &&
                            <div className="nodrugs">{getDrugName(drugs[0])} was the only substance found.</div>
                        }
                        <div className="drugslist">
                            {!props.isLiquid && drugs.length > 0 && topDrugs.length > 0 && topDrugs.map((d: DrugFound, index: number) => {
                                return (
                                    <div className="drugrow" key={index}>
                                        <div>
                                            {displayFormula(d.weight && d.weight > 0 ? d.weight : undefined, props.weight && props.weight > 0 ? props.weight : undefined)}
                                        </div>
                                        <div>&nbsp;({d.weight && props.weight && <>{((+d.weight / +props.weight) * 100).toFixed(2)}%</>})</div>
                                        <div>
                                            <b>{getDrugName(d)}</b> {d.drug?.important && <Noteworthy />}
                                        </div>
                                    </div>
                                );
                            })}
                            {!props.isLiquid && drugs.length > 1 && bottomDrugs.length > 0 && topDrugsPercent > 0 &&
                                <div className="drugrow martop">
                                    <div>
                                        {bottomDrugsWeight >= 0 && <>{displayFormula(bottomDrugsWeight, props.weight && props.weight > 0 ? props.weight : undefined)}</>}
                                    </div>
                                    <div>
                                        {props.weight && props.weight > 0 && <>({((bottomDrugsWeight / props.weight) * 100).toFixed(2)}%)</>}
                                    </div>
                                    <div>Remaining substances found include:</div>
                                </div>
                            }
                            {!props.isLiquid && isQuantified && drugs.length > 0 && bottomDrugs.length === 0 &&
                                <div className="drugrow martop">
                                    <div className="dfrow">
                                        {cannotIdentify > 0 &&
                                            <><span>{(cannotIdentify / 10).toFixed(2)}</span> <span className="mg">mg</span></>
                                        }
                                    </div>
                                    <div>({cannotIdentify.toFixed(2)}%)</div>
                                    <div>Remaining contains substances we cannot identify.</div>
                                </div>
                            }
                            {hasIndicator &&
                                <div className="drugrow martop">


                                    <div className="list">
                                        {bottomDrugs.map((d: DrugFound, index: number) => {
                                            return (<div key={index}><b>{getDrugName(d)}</b> {d.drug?.important && <Noteworthy />}</div>)
                                        })}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            }
            {performs_analysis && noDrugsFound &&
                <div className="title">
                    <div>
                        <p style={{marginBottom: '1rem'}}>Substances Found:</p>
                        <div className="nodrugs">No Substances Found</div>
                    </div>
                </div>
            }
            {performs_analysis && (props.isDraft || (!props.isDraft && drugs.length > 0)) &&
                <div className=" mb-2">
                    <table className="drugfoundtable">
                        <thead>
                            <tr style={{height: '47px'}}>
                                <th style={{width: '10px'}}></th>
                                <th style={{width: '29%'}}>Substance Name </th>
                                <th style={{width: '15%'}}>Relative %</th>
                                {!props.isLiquid && <th style={{width: '17%'}}>Quantity (mg)</th>}
                                <th style={{width: '8%'}}>Public</th>
                                <th>Alias</th>
                            </tr>
                        </thead>
                        <tbody>
                            {drugs.sort((a, b) => b.percent - a.percent).map((drug: DrugFound, index: number) => {
                                return (
                                    <tr key={index} style={{height: '47px'}}>
                                        <td>{index + 1}</td>
                                        <td><b>{drug.custom_drug ? drug.custom_drug : drug.drug?.name}</b></td>
                                        <td><b>{drug.percent}</b></td>
                                        {!props.isLiquid && <td><b>{drug.weight}</b></td>}
                                        <td>
                                            {(drug.custom_drug || (drug.drug && drug.drug.report_to_public)) &&
                                                <Icons.IsPublic/>
                                            }
                                        </td>
                                        <td>
                                            {drug.drug ? drug.drug.alias ? drug.drug.alias : "---" : ""}
                                        </td>
                                    </tr>
                                )
                            })}
                            {props.isDraft && drugs.length === 0 &&
                                <tr style={{height: '40px'}}>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    {!props.isLiquid && <td></td>}
                                    <td></td>
                                    <td></td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            }
            {!performs_analysis &&
                <div className="drugdictionary">
                    <div>Not sure what some of these drugs are?</div>
                    <a href="https://drugchecking.community/drug-dictionary/" target="_blank" rel="noreferrer">
                        View substances dictionary
                    </a>
                </div>
            }
            {!performs_analysis && <hr />}
        </div>
    );
}

export default DrugsFound;
